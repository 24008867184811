//@ts-nocheck
import { validator } from 'app/helpers/validator';
import { Controller } from 'react-hook-form';
import ReactInputMask from 'react-input-mask';
import { UIInputProps } from 'shared/ui/UIInput/types';
import UIInput from 'shared/ui/UIInput/UIInput';
import BaseFormInputProps from '../types';

function FormPhoneInput<T>({
  name,
  control,
  rules,
  defaultValue,
  ignoreDefaultPattern,
  // ** disabled this code just for the testing period. The mask was working great.
  // mask = '+1 (999) 999-9999',
  mask = '',
  isArrayField,
  nameKey,
  position,
  disabled,
  ...rest
}: BaseFormInputProps<T> & UIInputProps & { mask?: string; ignoreDefaultPattern?: boolean }) {
  return (
    <Controller
      defaultValue={defaultValue}
      // ** disabled this code just for the testing period. The mask was working great.
      // rules={{
      //   ...rules,
      //   pattern: ignoreDefaultPattern
      //     ? rules?.pattern
      //     : {
      //         value: validator.phone,
      //         message: 'Format is wrong',
      //       },
      // }}
      rules={
        name === !'snn' &&
        !'tnn' && {
          required: 'Required field',
          validate: (value) => {
            console.log('phone value: ', value);
            if (!value.replace(/\D/g, '').length) {
              return 'Required field';
            }
          },
        }
      }
      control={control}
      name={name}
      // ** disabled this code just for the testing period. The mask was working great.
      // render={({ field: { onChange, value, onBlur }, formState: { errors } }) => {
      //   return (
      //     <ReactInputMask maskChar=' ' onChange={onChange} value={(value as string) ?? ''} mask={mask} onBlur={onBlur}>
      //       {(props) => {
      //         return (
      //           <UIInput
      //             {...rest}
      //             {...props}
      //             error={isArrayField ? !!errors[nameKey]?.[position]?.value?.message : !!errors[name]?.message}
      //             helperText={
      //               <>{(isArrayField ? errors[nameKey]?.[position]?.value?.message : errors[name]?.message) ?? ''}</>
      //             }
      //           />
      //         );
      //       }}
      //     </ReactInputMask>
      //   );
      // }}

      render={({ field: { onChange, value, onBlur }, fieldState: { error } }) => (
        <UIInput
          onChange={onChange}
          value={value ?? ''}
          onBlur={onBlur}
          placeholder='+1 (   )    -'
          error={!!error}
          helperText={error ? error.message : ''}
          style={{ width: '100%' }}
        />
      )}
    />
  );
}

export default FormPhoneInput;
